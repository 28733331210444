import { Consts } from '@/_helpers/consts'

export default {
  methods: {
    showError(text) {
      return this.showAlert(Consts.ALERT_TYPE_ERROR, text)
    },
    showInfo(text) {
      return this.showAlert(Consts.ALERT_TYPE_INFO, text)
    },
    showAlert(type, text) {
      return this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type, text })
    }
  }
}
