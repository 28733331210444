<template>
  <div><update-buyers-info-form ref="form"></update-buyers-info-form></div>
</template>

<script>
import UpdateBuyersInfoForm from '@/components/organism/UpdateBuyersInfoForm'

export default {
  components: { UpdateBuyersInfoForm },
  beforeRouteLeave (to, from, next) {
    this.$refs.form.beforeRouteLeave(to, from, next)
  }
}
</script>
