<template>
  <div class="form">
    <div class="form__header row" :class="{ 'form__header--sticky': isSticky }">
      <div class="form__header__back col-md-2 col-md-2 col-xs-10 offset-xs-1 offset-md-0">
        <router-link v-bind:to="projectUrl" class="form__header__back__link">{{ $t('cancel') }}</router-link>
      </div>
      <div class="form__header__main col-12 col-xs-10 col-md-4 col-xl-5 offset-xs-1 offset-md-0">
        <h1 v-if="hasAlreadyBuyer" class="form__header__title">{{ $t('buyerChangesTitle') }}</h1>
        <h1 v-else class="form__header__title">{{ $t('buyerInfoTitle') }}</h1>
      </div>

      <div class="form__header__utils col-12 col-xs-10 col-md-6 col-xl-5 offset-xs-1 offset-md-0">
        <button-gcr
          btnClass="btn-primary btn-sm btn-arrow form__header__btn"
          @btnclick="submitSaveBuyers()"
          :disabled="isSAC"
          :label="saveBtnLabel"
          :loading="saving"
        />
      </div>
    </div>
    <div class="form__body row">
      <div class="form__container col-xs-10 col-sm-8 col-md-6 offset-xs-1 offset-md-2">
        <!-- /// -->
        <p v-show="hasAlreadyBuyer" v-html="$t('buyerMoreInfoDescTop')" />

        <div v-for="(buyer, index) in buyers" :key="buyer.id" class="o-buyer">
          <h3 class="form__subtitle">
            {{ $t('buyer') }} {{ index + 1 }}
            <inline-confirmation-button-gcr
              btnClass="btn-delete btn-delete-sm"
              @confirm="removeBuyer(buyer)"
              :disabled="isSAC"
              confirmText="deleteBuyerConfirmation"
              confirmClass="btn-primary btn-sm"
            />
          </h3>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <input-gcr
                data-test-id="buyer-first-name"
                label="buyerFirstName"
                v-model="buyer.firstName"
                :submitted="buyer.submitted"
                invalidText="buyerFirstNameRequired"
              />
            </div>
            <div class="col col-sm-6 form__col">
              <input-gcr
                data-test-id="buyer-last-name"
                label="buyerLastName"
                v-model="buyer.lastName"
                :submitted="buyer.submitted"
                invalidText="buyerLastNameRequired"
              />
            </div>
          </div>

          <div class="row">
            <div class="col col-xs-8 form__col">
              <input-gcr
                data-test-id="buyer-email"
                label="buyerEmail"
                v-model="buyer.email"
                :submitted="buyer.submitted"
                invalidText="buyerEmailRequired" />
            </div>
          </div>

          <div class="row">
            <div class="col col-xs-8 form__col">
              <input-gcr
                data-test-id="buyer-phone-number"
                label="buyerPhoneNumber"
                v-model="buyer.phoneNumber"
                :submitted="buyer.submitted"
                invalidText="buyerPhoneNumberRequired"
              />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <input-gcr label="buyerOfficePhoneNumber" v-model="buyer.officePhoneNumber" :optional="true" />
            </div>
            <div class="col col-sm-6 form__col">
              <input-gcr label="buyerOfficePhoneExt" v-model="buyer.officePhoneExt" :optional="true" />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-4 form__col">
              <input-gcr
                data-test-id="buyer-street-number"
                label="buyerStreetNumber"
                v-model="buyer.streetNumber"
                :submitted="buyer.submitted"
                invalidText="buyerStreetNumberRequired"
              />
            </div>
            <div class="col col-sm-8 form__col">
              <input-gcr
                data-test-id="buyer-street"
                label="buyerStreet"
                v-model="buyer.street"
                :submitted="buyer.submitted"
                invalidText="buyerStreetRequired"
              />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <input-gcr data-test-id="buyer-apartment" label="buyerApartmentNumber" v-model="buyer.apartment" :optional="true" />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <input-gcr data-test-id="buyer-city" label="city" v-model="buyer.city" :submitted="buyer.submitted" invalidText="cityRequired" />
            </div>
            <div class="col col-sm-6 form__col">
              <input-gcr data-test-id="buyer-postal-code" label="buyerPostalCode" v-model="buyer.postalCode" :submitted="buyer.submitted"
                         invalidText="buyerPostalCodeRequired" />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <input-gcr data-test-id="buyer-province" label="buyerProvince" v-model="buyer.province" :submitted="buyer.submitted"
                         invalidText="buyerProvinceRequired" />
            </div>
            <div class="col col-sm-6 form__col">
              <input-gcr data-test-id="buyer-country" label="buyerCountry" v-model="buyer.country" :submitted="buyer.submitted"
                         invalidText="buyerCountryRequired" />
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-6 form__col">
              <date-gcr data-test-id="buyer-birthdate" label="buyerBirthdate" v-model="buyer.birthdate" :optional="true" />
            </div>
          </div>

          <div class="row">
            <div class="col form__col form__btn form__btn--left">
              <button-gcr
                data-test-id="add-buyer-button"
                @btnclick="addBuyer"
                btnClass="btn-outline-gray btn-sm btn-add"
                label="buyerAddBtn"
                v-show="canAddMoreBuyer"
              />
            </div>
          </div>
        </div>

        <p v-show="hasAlreadyBuyer" v-html="$t('buyerMoreInfoDescBottom')" />
      </div>
    </div>
    <div class="form__footer row">
      <div class="form__buttons col-xs-11 col-md-10 offset-xs-1 offset-md-2">
        <div class="form__buttons__left">
          <router-link v-bind:to="projectUrl" class="btn btn-outline-gray btn-arrow-back btn-sm form__footer__btn">{{
              $t('cancel')
            }}
          </router-link>
        </div>
        <div class="form__buttons__right">
          <button-gcr
            data-test-id="save-buyers-button"
            btnClass="btn-primary btn-sm btn-arrow form__footer__btn"
            @btnclick="submitSaveBuyers()"
            :disabled="isSAC"
            :label="saveBtnLabel"
            :loading="saving"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Consts, Urls } from '@/_helpers'

import InlineConfirmationButtonGcr from '@/components/molecules/InlineConfirmationButton'
import ButtonGcr from '@/components/atoms/Button'
import InputGcr from '@/components/atoms/Input'
import DateGcr from '@/components/atoms/Date'
import SearchSelectGcr from '@/components/atoms/SearchSelect'
import form from '@/components/mixins/form'
import alerts from '@/components/mixins/alerts'
import getProjectBuyers from '@/graphql/accreditations/projects/getProjectBuyers.graphql'
import saveProjectBuyers from '@/graphql/accreditations/projects/saveProjectBuyers.graphql'
import { mapGetters } from 'vuex'

require('../../utilities/form')

const MAX_BUYER_COUNT = 2
const MIN_BUYER_COUNT = 1

export default {
  components: { InputGcr, DateGcr, ButtonGcr, InlineConfirmationButtonGcr, SearchSelectGcr },
  mixins: [form, alerts],
  data() {
    return {
      hasAlreadyBuyer: false,
      saving: false,
      buyers: [],
      incrementID: 0,
      projectUrl: {
        name: Urls.PROJECT_DETAILS,
        query: {
          id: this.$route.query.id,
          ct: 'contracts'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC
    }),
    canAddMoreBuyer() {
      return this.buyers.length < MAX_BUYER_COUNT
    },
    saveBtnLabel() {
      return this.hasAlreadyBuyer ? 'buyerSaveModificationBtn' : 'buyerSaveBtn'
    }
  },
  methods: {
    submitSaveBuyers() {
      this.buyers.forEach(x => x.submitted = true)
      if (!this.validate()) {
        return
      }
      this.saveBuyers().then(() => {
        this.dirty = false
        this.$router.push(this.projectUrl)
        this.showInfo(this.hasAlreadyBuyer ? 'updateBuyerSucceed' : 'createBuyerSucceed')
      })
    },
    saveBuyers() {
      this.saving = true
      return this.$apollo
        .mutate({
          mutation: saveProjectBuyers,
          variables: {
            input: {
              accreditationId: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
              projectId: this.$route.query.id,
              buyers: this.buyers
                .filter(this.validateBuyer)
                .map(x => {
                  return {
                    apartment: x.apartment,
                    birthdate: x.birthdate,
                    city: x.city,
                    country: x.country,
                    email: x.email,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    officePhoneExt: x.officePhoneExt,
                    officePhoneNumber: x.officePhoneNumber,
                    phoneNumber: x.phoneNumber,
                    postalCode: x.postalCode,
                    province: x.province,
                    street: x.street,
                    streetNumber: x.streetNumber
                  }
                })
            }
          }
        })
        .finally(() => {
          this.saving = false
        })
    },
    validateBuyer(buyer) {
      return buyer.city &&
        buyer.email &&
        buyer.firstName &&
        buyer.lastName &&
        buyer.phoneNumber &&
        buyer.street &&
        buyer.streetNumber &&
        buyer.province &&
        buyer.country &&
        buyer.postalCode
    },
    validate() {
      return this.buyers.every(this.validateBuyer)
    },
    addBuyer() {
      this.buyers.push({
        submitted: false,
        id: this.incrementID++,
        apartment: '',
        birthdate: '',
        city: '',
        country: 'Canada',
        email: '',
        firstName: '',
        lastName: '',
        officePhoneExt: '',
        officePhoneNumber: '',
        phoneNumber: '',
        postalCode: '',
        province: 'Québec',
        street: '',
        streetNumber: ''
      })
    },
    async removeBuyer(buyer) {
      const index = this.buyers.indexOf(buyer)
      this.buyers.splice(index, 1)

      if (buyer.fromServer) {
        await this.saveBuyers()
        this.showInfo('updateBuyerSucceed')
      }

      if (this.buyers.length < MIN_BUYER_COUNT) {
        this.addBuyer()
      }
    }
  },
  apollo: {
    getoneproject: {
      query: getProjectBuyers,
      variables() {
        return {
          accredid: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
          projectid: this.$route.query.id
        }
      },
      update(data) {
        if (data.accreditation.project.buyers.length > 0) {
          this.hasAlreadyBuyer = true
          this.buyers = data.accreditation.project.buyers.map(x => {
            return { id: this.incrementID++, fromServer: true, ...x }
          })
        } else if (this.buyers.length < MIN_BUYER_COUNT) {
          this.hasAlreadyBuyer = false
          this.addBuyer()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.o-buyer {
  & + & {
    margin-top: 60px;
  }
}
</style>
